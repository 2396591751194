import { environment } from "./environment";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

// jamaat level configuration is stored here. This helps automate dynamic configuration load on app based on local storage jamaat value
@Injectable({
  providedIn: 'root'
})
export class JamaatConfig {

    config: IJamaatConfig[];
    selectedJamaat: string
    constructor(private tokenService: TokenStorageService, private http: HttpClient) {

    }

    // this will be called in AppModule to initialize all tokens asynchronously
    // A  pre-requisite to load correct storage values asynchronously before any jamaat level config is accessed by child components and services.
    async loadAppConfig() {

        const tenantEndpoint = environment.tenantEndpoint;
        const headers = new HttpHeaders().set('x-tenant-id', 'root');

        // resolve the call and return the response of this function
        const obs$ =  this.http.get(tenantEndpoint, { headers: headers });

        const response = await firstValueFrom(obs$);
        this.config = (response as IJamaatConfig[]).filter(x => !x.hidden);
        await this.tokenService.__loadConfig(); // load token service with browser set jamaat
        
        return;
      //return this.tokenService.loadConfig();
      
    }

  
    public  currentConfig(): IJamaatConfig {
        
        let jamaat = this.tokenService.getJamaat();
        let currentConfig =  this.config.find(x => x.jamaatName === jamaat);

        return currentConfig!;
        // else
        //     return this.config[0];
       
        // switch (jamaat) {
        //     case environment.sydney.jamaatName:
        //         return environment.sydney;
        //     case environment.brisbane.jamaatName:
        //         return environment.brisbane;
        //     case environment.dha.jamaatName:
        //           return environment.dha;
        //     case environment.clifton.jamaatName:
        //         return environment.clifton;
        //     default: // return sydney as default
        //         return environment.sydney;
        // }
    }
  
    public  currentJamaat() : string  {
      if (!this.selectedJamaat)
        return this.tokenService.getJamaat();

       return this.selectedJamaat
    }

    public saveJamaat(jamaat: string) {

      this.selectedJamaat = jamaat;
      
      return this.tokenService.saveJamaat(jamaat);
    }

    public getJamaatList()  {

      return this.config;      
    //   return [
    //     { display: 'Sydney', value: 'sydney'},
    //     { display: 'Brisbane', value: 'brisbane'},
    //     { display: 'Karachi Defence', value: 'dha'},
    //     { display: 'Karachi Clifton', value: 'clifton'},

    //   ];
    // }
    }
  }
  
  export interface IJamaatConfig {
  
    frontEndHostname: string;
    jamaatName: string; // this is legacy, but required localstorage migration
    displayName: string; // how to show in login screen
    tenantName: string; // actual backend db
    backend: string;
    timezone: string;
    dateLocale: string;
    siteTitle: string;
    defaultCountry: string;
    hidden: boolean;
    customOptions: {
        hideEmptyAreasThaaliSummary: boolean,
        showMemberWeekDays: boolean,
        showRiceOption: boolean, // show rice options on thaali create/edit screen or member preferences
        showBreadOption: boolean, // show bread options on thaali create/edit screen or member preferences
        showQtyOption: boolean, // show half/full thaali options on thaali create/edit screen or member preferences
        showThaaliCount: boolean, // should set showQtyOption = false for this to be true, enables thaali count feature instead of qty
        defaultExcludeRice: boolean, // exclude rice by default when creating new thaalis
        defaultExcludeBread: boolean, // exclude bread by default when creating new thaalis
        defaultExcludeQty: boolean, // exclude quantity selection by default when creating new thaalis
        showFeedbackOwner: boolean, // disclose name of person to admin that gave feedback
        memberNoticeHeading: string // this is the heading for member custom notice 
        eventsEnabled: boolean // option to enable feature for events on member page and admin screens
        useThaaliNumber: boolean // use thaali number as a field in members (karachi clifton jamaat)
        feedbackOpensAt: number; // hour of the day feedback is visible to members
        disableMemberRSVP: boolean; // disable rsvp feature for members, this is for newer jamaat where logistics are not aligned such as Clifton, DHA
        allowExportFeedbackCSV: boolean; // allow download of feedback csv export
        suppressThaaliMobileNotification: boolean // default mobile notification policy for thaali
        supresssEventMobileNotification: boolean // ...for event
        disableCoordinatorThaaliControls: boolean // hides coordinator to context menu and control thaali settings on behalf of members
        labels: {
          halfThaali : string;
          regularThaali : string;
          thaaliNumber: string; // show string if provided such as Sabeel No in Nalasopara 
          breadLabel: string;
        }
    }
  
  }