/* deployment commands: 

ng build --prod
cd dist/fmb
aws --profile fmb s3 sync . s3://fmb.sydneyjamaat.com/ --delete --cache-control max-age=31536000
aws --profile fmb cloudfront create-invalidation --distribution-id E1WDKI5AO7LMWH --paths "/*"
** Optional below as index.html is not cached ***

*/

export const environment = {

  testAccessKey : '',
  production: true,
  reCaptchaSiteKey: '6LfrRVAaAAAAAEfAdCmxo_mS3RI-8KtIG7eBA11a',
  tenantEndpoint: 'https://fmbapi.noorix.com/api/tenant/',


  appVersion: {
    appStoreRedirection: true,
    currentVersion: "1.5.4", // This should sync with app binary version in AppStore and Google Play Store
    codePushVersion: "29", // this is internal web version that can be pushed on deployed apps
    appleAppUrl : "https://apps.apple.com/au/app/fmb-sydney/id1584522268",
    googleAppUrl: "https://play.google.com/store/apps/details?id=com.sydneyjamaat.fmb",
    IOS_DEPLOY_KEY: "b_aW_c6LH53TS6jf9_EZkkDSKgXFbnX_Pa3Am", 
    ANDROID_DEPLOY_KEY: "k2tXru3kBNvXLbQ9CbkY_i1COHLYhGX0qucjM",
    
  }
}

