import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

const TOKEN_KEY = 'fmb-auth-token';
const USER_KEY = 'fmb-auth-user';
// this is to save UUID in Android and IOS apps nly
const MEMBER_UUID = 'fmb-member-uuid';
const JAMAAT = 'fmb-jamaat';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private jamaat: string | null;
  private uuid : string | null;
  private user:  any ;
  private token : string | null;

  constructor() { 
    // Preferences.get({key: JAMAAT}).then((value:any) => this.jamaat = value.value);
    // Preferences.get({key: MEMBER_UUID}).then((value:any) => this.uuid = value.value);
    // Preferences.get({key: TOKEN_KEY}).then((value:any) => this.token = value.value);
    // Preferences.get({key: USER_KEY}).then((value:any) => this.user = JSON.parse(value.value as string));
  
  }

  async __loadConfig() {
    

      this.uuid = (await Preferences.get( { key: MEMBER_UUID })).value ;
      this.token =  (await Preferences.get( { key: TOKEN_KEY })).value;
      this.jamaat =  (await Preferences.get( { key: JAMAAT })).value;
      this.user = JSON.parse((await Preferences.get( { key: USER_KEY })).value as string);
      
      return;
   
  }

  async signOut() {
    // window.localPreferences.removeItem(TOKEN_KEY);
    // window.localPreferences.removeItem(USER_KEY);
    // window.localPreferences.removeItem(MEMBER_UUID);
    await Preferences.remove({ key: TOKEN_KEY});
    await Preferences.remove({ key: USER_KEY});
    await Preferences.remove({ key: MEMBER_UUID});

    this.token = null;
    this.user = null;
    this.uuid = null;

    return;

    //window.localPreferences.removeItem(JAMAAT); Don't remove jamaat. It's useful for next login drop down and appmodule init
  }

  public async saveToken(token: string) {

    this.token = token;

    await Preferences.remove({ key: TOKEN_KEY});
    await Preferences.set({ key: TOKEN_KEY, value: token });
  
    return;
    // window.localPreferences.removeItem(TOKEN_KEY);
    // window.localPreferences.setItem(TOKEN_KEY, token);
  }

  public async saveUUID(uuid: string) {
    
    await Preferences.remove({ key: MEMBER_UUID});
    await Preferences.set({ key: MEMBER_UUID, value: uuid });

    this.uuid = uuid;
    return;
  }

  public async saveJamaat(jamaat: string) {

    await Preferences.remove({ key: JAMAAT});
    await Preferences.set({ key: JAMAAT, value: jamaat });
    this.jamaat = jamaat;
    return ;
  }

  public getJamaat() : string  {
    
   return this.jamaat || 'sydney';
   
  }

  public getToken(): string | null {
   // return window.localPreferences.getItem(TOKEN_KEY);
   return this.token;
  }

  public async saveUser(user: any) {
   // window.localPreferences.removeItem(USER_KEY);
  //  window.localPreferences.setItem(USER_KEY, JSON.stringify(user));
    this.user = user;
    await Preferences.remove({ key: USER_KEY})
    await Preferences.set({ key: USER_KEY, value: JSON.stringify(user) })
    return;
  }

  public getMemberUUID() {
  //  return window.localPreferences.getItem(MEMBER_UUID) || '';
    return this.uuid || '';
  }

  public getUser(): any {
   return this.user;
  }

  // public async setOrg(jamaat: string) {
  //   this.jamaat = jamaat;
  //      //window.localPreferences.setItem(JAMAAT, jamaat);
  //   await Preferences.remove({ key: JAMAAT});
  //   await Preferences.set({ key: JAMAAT, value: jamaat });
      
  //   return;
  // }

  // public getOrg(): string {
  //  // return window.localPreferences.getItem(JAMAAT) || 'sydney'; // default to sydney
  //  return this.jamaat || 'sydney';
  // }


  
}