import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JamaatConfig } from 'src/environments/jamaatconfig';
import { AppVersion } from '../interfaces/appversion.interface';
import { AreaData } from '../interfaces/areadata.interface';
import { CookData } from '../interfaces/cookdata.interface';
import { EventData } from '../interfaces/eventdata.interface';
import { MemberData } from '../interfaces/memberdata.interface';
import { ThaaliData } from '../interfaces/thaalidata.interface';
import { VenueData } from '../interfaces/venuedata.interface';

@Injectable({
  providedIn: 'root'
})
export class RestAPIService {

  constructor(private http: HttpClient,  private jamaatConfig: JamaatConfig
    ) {
     }

  private baseUrl = this.jamaatConfig.currentConfig().backend;
    

  getAppVersion(): Observable<AppVersion> {
    return this.http.get<AppVersion>(`${this.baseUrl}/appversion`);

  }

  // COOK APIS
  getCook(id: String) {
    return this.http.get(`${this.baseUrl}/cook/${id}`);
  }

  getCooks(): Observable<CookData[]> {

    return this.http.get<CookData[]>(`${this.baseUrl}/cook`);


  }
  addCook(cook: CookData) {
    return this.http.post(`${this.baseUrl}/cook`, cook);

  }
  updateCook(cook: CookData) {
    return this.http.put(`${this.baseUrl}/cook/${cook._id}`, cook);

  }

  deleteCook(id: String) {
    return this.http.delete(`${this.baseUrl}/cook/${id}`);

  }

  // AREA API
  getArea(id: String): Observable<AreaData> {
    return this.http.get<AreaData>(`${this.baseUrl}/area/${id}`);
  }

  getAreas(enabledOnly?: boolean): Observable<AreaData[]> {
    if (enabledOnly)
      return this.http.get<AreaData[]>(`${this.baseUrl}/enabledarea`);

    else
      return this.http.get<AreaData[]>(`${this.baseUrl}/area`);

  }

  addArea(area: AreaData) {
    return this.http.post(`${this.baseUrl}/area`, area);

  }
  updateArea(area: AreaData) {
    return this.http.put(`${this.baseUrl}/area/${area._id}`, area);

  }

  deleteArea(id: String) {
    return this.http.delete(`${this.baseUrl}/area/${id}`);

  }

   // VENUE API
   getVenue(id: String): Observable<AreaData> {
    return this.http.get<AreaData>(`${this.baseUrl}/venue/${id}`);
  }

  getVenues(): Observable<VenueData[]> {
        return this.http.get<VenueData[]>(`${this.baseUrl}/venue`);

  }

  addVenue(venue: VenueData) {
    return this.http.post(`${this.baseUrl}/venue`, venue);

  }
  updateVenue(venue: VenueData) {
    return this.http.put(`${this.baseUrl}/venue/${venue._id}`, venue);

  }

  deleteVenue(id: String) {
    return this.http.delete(`${this.baseUrl}/venue/${id}`);

  }

  // Thaali API
  getMyThaalis(id: String) {
    return this.http.get(`${this.baseUrl}/memberthaali/${id}`);
  }

  getAreaThaalis(areaId: String) {
    // only admin has permissions to invoke this API
    return this.http.get(`${this.baseUrl}/thaali/area/${areaId}`);
  }


  updateMyThaaliPreferences(thaaliPref: any) {
    return this.http.put(`${this.baseUrl}/memberthaali/${thaaliPref._id}`, thaaliPref);

  }

  getThaaliSummary(id: String) {
    return this.http.get(`${this.baseUrl}/thaalisummary/${id}`);
  }

  getThaaliCountSummary(id: String) {
    return this.http.get(`${this.baseUrl}/thaalisummary-bycount/${id}`);
  }



  getThaali(id: String): Observable<ThaaliData> {
    return this.http.get<ThaaliData>(`${this.baseUrl}/thaali/${id}`);
  }

  getThaaliForToday(areaId: String): Observable<ThaaliData> {
    // fetches the current thaali
    return this.http.get<ThaaliData>(`${this.baseUrl}/activethaali/${areaId}`);
  }


  getThaalis(page: number, limit: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/thaali/${page}/${limit}`);

  }
  getPastThaalis(page: number, limit: number): Observable<any> {

    return this.http.get(`${this.baseUrl}/pastthaali/${page}/${limit}`);

  }

  addThaali(thaali: ThaaliData) {
    return this.http.post(`${this.baseUrl}/thaali`, thaali);

  }

  publishThaali(thaaliId: string) {
    return this.http.put(`${this.baseUrl}/thaali/publish/${thaaliId}`, null);

  }

  updateThaali(thaali: ThaaliData) {
    return this.http.put(`${this.baseUrl}/thaali/${thaali._id}`, thaali);

  }

  deleteThaali(id: String) {
    return this.http.delete(`${this.baseUrl}/thaali/${id}`);

  }

  getThaaliMembersByArea(thaaliId: string, areaId: string) {
    return this.http.get(`${this.baseUrl}/thaalimembers/${thaaliId}/${areaId}`);

  }

  // VENUE APIs
  // Thaali API
  getMyEvents(id: String) {
    return this.http.get(`${this.baseUrl}/memberevents/${id}`);
  }


  getEventSummary(id: String) {
    return this.http.get(`${this.baseUrl}/eventsummary/${id}`);
  }

  getThaalisForCook(id: string, historic: boolean = false) {
    const url = `${this.baseUrl}/cook/${id}/thaali${historic ? '?historic=true' : ''}`;
    return this.http.get(url);
  }


  getEvent(id: String): Observable<EventData> {
    return this.http.get<EventData>(`${this.baseUrl}/event/${id}`);
  }


  getUpcomingEvents(page: number, limit: number): Observable<EventData[]> {
    return this.http.get<EventData[]>(`${this.baseUrl}/upcoming-events/${page}/${limit}`);

  }
  getPastEvents(page: number, limit: number): Observable<EventData[]> {

    return this.http.get<EventData[]>(`${this.baseUrl}/past-events/${page}/${limit}`);

  }

  createEvent(event: EventData) {
    return this.http.post(`${this.baseUrl}/event`, event);

  }

  publishEvent(eventId: string) {
    return this.http.put(`${this.baseUrl}/event/publish/${eventId}`, null);

  }

  updateEvent(event: EventData) {
    return this.http.put(`${this.baseUrl}/event/${event._id}`, event);

  }

  deleteEvent(id: String) {
    return this.http.delete(`${this.baseUrl}/event/${id}`);

  }

  updateEventRSVP(eventRSVP: any) {
    return this.http.put(`${this.baseUrl}/memberevent/${eventRSVP._id}`, eventRSVP);

  }

  getVenueAttendanceList(eventId: string, venueId:string) {
    return this.http.get(`${this.baseUrl}/venue-attendance/${eventId}/${venueId}`);

  }


  ////////////


  getFeedback(thaaliTransactionId: string) {
    return this.http.get(`${this.baseUrl}/feedback/${thaaliTransactionId}`);


  }
  saveFeedback(feedback: any) {
    return this.http.put(`${this.baseUrl}/feedback/`, feedback);


  }

  rateThaali(transId: string, rating: number) {
    return this.http.put(`${this.baseUrl}/memberthaali/rate/${transId}`, { rating: rating });


  }


  // MEMBER API
  getMember(id: String): Observable<MemberData> {
    return this.http.get<MemberData>(`${this.baseUrl}/member/${id}`);
  }

  getMemberByUUID(id: String): Observable<MemberData> {
    return this.http.get<MemberData>(`${this.baseUrl}/member/uuid/${id}`);
  }

  markThaaliDelivered(thaaliTransactionId: String, delivered: boolean) {
    return this.http.put(`${this.baseUrl}/memberthaali/markdelivered/${thaaliTransactionId}`, { delivered: delivered });

  }


  getMemberForCoordinator() {
    return this.http.get<MemberData[]>(`${this.baseUrl}/members-for-coordinator`);
  }


  getMembers(hideDisabled:boolean, searchString:string, page: number, limit: number, areaList:string[]): Observable<{ 
    totalPages: number,
    currentPage: number,
    totalRecords: number,
    members: MemberData[]
  }> {
    const params = new HttpParams()
      .set('search', searchString)
      .set('hideDisabled', hideDisabled)
      .set('page', page)
      .set('limit',limit)
      .set('areas', areaList.join(','));
    
    return this.http.get<{ 
      totalPages: number,
      currentPage: number,
      totalRecords: number,
      members: MemberData[]}>(`${this.baseUrl}/member?${params.toString()}`);

  }

  exportMembers() {
    return this.http.get(`${this.baseUrl}/exportMembers`);

  }
  addMember(member: MemberData) {
    
    return this.http.post(`${this.baseUrl}/member`, member);

  }
  updateMember(member: MemberData) : Observable<MemberData> {
    return this.http.put<MemberData>(`${this.baseUrl}/member/${member._id}`, member);

  }

  updateMemberPermissions(member: MemberData) : Observable<MemberData> {
    return this.http.put<MemberData>(`${this.baseUrl}/member-permission/`, member);

  }

  subscribePushNotification(memberId: string, subscription: any) {
    return this.http.put(`${this.baseUrl}/member/pushsubscription/${memberId}`, { pushSubscription: subscription });

  }

  setPassword(data: any) {
    return this.http.put(`${this.baseUrl}/password/${data._id}`, data);


  }

  deleteMember(id: String) {
    return this.http.delete(`${this.baseUrl}/member/${id}`);

  }


  public getDailyReminderConfig(reminderType: string) {
    return this.http.get(`${this.baseUrl}/notification/reminder-config?reminderType=${reminderType}`);
  }

  public updateDailyReminderConfig(reminderType: string, config: { state: boolean, date: Date }) {
      const params = new HttpParams().set('reminderType', reminderType);
      return this.http.post(`${this.baseUrl}/notification/reminder-config`, config, { params });
  }

  public disableDailyReminderConfig(reminderType: string) {
      const params = new HttpParams().set('reminderType', reminderType);
      return this.http.delete(`${this.baseUrl}/notification/reminder-config`, { params });
  }

  public sendManualDailyReminder(reminderType: string) {
      const params = new HttpParams().set('reminderType', reminderType);
      return this.http.post(`${this.baseUrl}/notification/reminder-send`, {}, { params });
  }


}
